import { template as template_01fed793a6584c32ac6992c606a2e363 } from "@ember/template-compiler";
const SidebarSectionMessage = template_01fed793a6584c32ac6992c606a2e363(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
