import { template as template_bb99160e2ebb42dd93a1318cea7ac2ad } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb99160e2ebb42dd93a1318cea7ac2ad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
